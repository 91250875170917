import {
  init,
  postEvent,
  backButton,
  isTMA,
  retrieveLaunchParams,
} from "@telegram-apps/sdk";
import { useEffect } from "react";

export const isTelegramMiniApp = isTMA("simple");

if (isTelegramMiniApp) {
  // Init the package and actualize all global dependencies.
  init({
    acceptCustomStyles: false,
  });

  const launchParams = retrieveLaunchParams();

  console.log("launchParams", launchParams);

  postEvent("web_app_setup_closing_behavior", { need_confirmation: true }); // eslint-disable-line camelcase
  postEvent("web_app_setup_swipe_behavior", { allow_vertical_swipe: false }); // eslint-disable-line camelcase
}

export function useReactRouterIntegration(location, navigate) {
  useEffect(() => {
    if (!isTelegramMiniApp) return;

    postEvent("web_app_setup_back_button", {
      is_visible: location.pathname !== "/", // eslint-disable-line camelcase
    });

    // When a user clicked the back button, go back in the
    // navigation history.
    if (backButton.onClick.isAvailable()) {
      const off = backButton.onClick(() => {
        off();
        navigate(-1);
      });

      return off;
    }
  }, [location, navigate]);
}
