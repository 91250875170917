import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StrikethroughDiv = styled("div")(() => ({
  position: "relative",
  padding: "0 2px",
  ["& :before"]: {
    content: '""',
    position: "absolute",
    right: 0,
    left: 0,
    top: "calc(50% - 1px)",
    width: "100%",
    height: "2px",
    background: "white",
  },
}));

export default function PriceButton({
  text,
  price,
  oldPrice,
  variant,
  ...props
}) {
  return (
    <Button
      disableElevation
      fullWidth
      variant={variant}
      color="primary"
      size="large"
      sx={{ minHeight: 64 }}
      {...props}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
      >
        {text && (
          <Grid item>
            <Box sx={{ fontWeight: 700 }}>{text}</Box>
          </Grid>
        )}
        <Grid item>
          <Box sx={{ fontSize: "1.6rem", fontWeight: 800, lineHeight: 1.2 }}>
            {price}
          </Box>

          {oldPrice && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StrikethroughDiv>{oldPrice}</StrikethroughDiv>
            </Box>
          )}
        </Grid>
      </Grid>
    </Button>
  );
}

PriceButton.defaultProps = {
  variant: "contained",
};

PriceButton.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined-product"]),
  text: PropTypes.string,
  oldPrice: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
