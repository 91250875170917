import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ArenaCover from "arena/components/ArenaCover";
import classNames from "classnames";
import Fade from "@mui/material/Fade";

const PREFIX = "ArenaCoverCarousel";

const classes = {
  first: `${PREFIX}-first`,
  last: `${PREFIX}-last`,
  item: `${PREFIX}-item`,
  dots: `${PREFIX}-dots`,
  dot: `${PREFIX}-dot`,
  dotActive: `${PREFIX}-dotActive`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  height: "100vh",
  marginBottom: -100,
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
  },

  [`&.${classes.first}`]: {
    marginTop: -54,
    [theme.breakpoints.up("sm")]: {
      marginTop: -64,
    },
  },

  [`&.${classes.last}`]: {
    marginBottom: 0,
  },

  [`& .${classes.item}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  [`& .${classes.dots}`]: {
    zIndex: 10,
    position: "absolute",
    left: 10,
    right: 10,
    display: "flex",
    justifyContent: "center",
    bottom: 120,
  },

  [`& .${classes.dot}`]: {
    padding: 0,
    border: 0,
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    margin: 10,
    cursor: "pointer",
  },

  [`& .${classes.dotActive}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
}));

export default function ArenaCoverCarousel({ arena, blocks, first, last }) {
  const [autoPlay, setAutoPlay] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (!autoPlay) return;
    if (!process.browser) return;
    if (blocks.length === 1) return;

    const timeout = setTimeout(() => {
      setSlideIndex((slideIndex) => {
        const nextIndex = slideIndex + 1;
        if (blocks[nextIndex]) {
          return nextIndex;
        }
        return 0;
      });
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [slideIndex, autoPlay, blocks]);

  const onChangeIndex = (event, slideIndex) => {
    setSlideIndex(slideIndex);
    setAutoPlay(false);
  };

  if (blocks.length === 1) {
    const [block] = blocks;
    return (
      <ArenaCover
        arena={arena}
        {...block.props}
        blockId={block.id}
        first={first}
        last={last}
      />
    );
  }

  return (
    <Root
      className={classNames({
        [classes.first]: first,
        [classes.last]: last,
      })}
    >
      {blocks.map((block, index) => (
        <Fade
          timeout={1000}
          appear={false}
          key={block.id}
          in={index === slideIndex}
        >
          <div className={classes.item}>
            <ArenaCover
              arena={arena}
              {...block.props}
              blockId={block.id}
              index={index + 1}
            />
          </div>
        </Fade>
      ))}

      <div className={classes.dots}>
        {blocks.map((block, index) => {
          return (
            <button
              aria-label={`Slide ${index + 1}`}
              key={block.id}
              className={classNames({
                [classes.dot]: true,
                [classes.dotActive]: index === slideIndex,
              })}
              onClick={(event) => onChangeIndex(event, index)}
            />
          );
        })}
      </div>
    </Root>
  );
}

ArenaCoverCarousel.defaultProps = {
  blocks: [],
};

ArenaCoverCarousel.propTypes = {
  arena: PropTypes.object.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};
