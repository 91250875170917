import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import { withSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomField from "arena/components/CustomField";
import { useMutation } from "@apollo/client";
import { UpdateSettingsMutation } from "arena/mutations";
import AvatarField from "arena/components/AvatarField";
import TimeZoneField from "arena/components/TimeZoneField";
import CountryField from "arena/components/CountryField";
import LanguageField from "arena/components/LanguageField";
import SettingsHeader from "arena/components/SettingsHeader";
import { useViewer } from "core/components/ViewerProvider";
import { setTimeZone as setGlobalTimeZone } from "core/utils/moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const PREFIX = "SettingsProfile";

const classes = {
  content: `${PREFIX}-content`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.content}`]: {
    overflow: "hidden",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
}));

function SettingsProfile({ onClose, enqueueSnackbar, arena, tabs }) {
  const { t, i18n } = useTranslation("arena");

  const viewer = useViewer();
  const [values, setValues] = useState({
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    language: viewer.language,
    country: viewer.country,
    timeZone: viewer.timeZone,
    customFields: viewer.customFields,
    agreeToMarketingNewsletters: viewer.agreeToMarketingNewsletters,
  });

  console.log(viewer, viewer.agreeToMarketingNewsletters);

  const onError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  };

  const onCompleted = () => {
    enqueueSnackbar(t("settingsProfile.updated"), {
      variant: "success",
    });
    onClose();
  };

  const [updateSettings, { loading }] = useMutation(UpdateSettingsMutation, {
    onError,
    onCompleted,
    variables: {
      input: values,
    },
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setGlobalTimeZone(values.timeZone);
    updateSettings();
  };

  return (
    <Root onSubmit={onSubmit}>
      <SettingsHeader
        onClose={onClose}
        title={t("settingsProfile.title")}
        action={
          <Button
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {t("save")}
          </Button>
        }
        tabs={tabs}
      />

      <DialogContent className={classes.content}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} container justifyContent="center">
            <AvatarField
              image={viewer?.photo?.url || viewer?.photoUrl}
              onError={onError}
              onChange={(photoId) => setValues({ ...values, photoId })}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t("settingsProfile.firstName")}
              value={values.firstName}
              onChange={(event) =>
                setValues({ ...values, firstName: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label={t("settingsProfile.lastName")}
              value={values.lastName}
              onChange={(event) =>
                setValues({ ...values, lastName: event.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CountryField
              required
              label={t("settingsProfile.country")}
              value={values.country}
              onChange={(country) => {
                setValues({ ...values, country, timeZone: "" });
              }}
            />
          </Grid>
          {arena?.multilingual && (
            <Grid item xs={12} md={6}>
              <LanguageField
                required
                label={t("onboardingDialog.profile.language")}
                value={values.language}
                onChange={(event) => {
                  i18n.changeLanguage(event.target.value);
                  setValues((values) => ({
                    ...values,
                    language: event.target.value,
                  }));
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TimeZoneField
              required
              country={values.country}
              label={t("settingsProfile.timeZone")}
              value={values.timeZone}
              onChange={(timeZone) => setValues({ ...values, timeZone })}
            />
          </Grid>

          {arena?.userFields?.map((userField, index) => (
            <Grid item xs={12} md={6} key={index}>
              <CustomField
                {...userField}
                value={values.customFields[userField.name]}
                onChange={(value) => {
                  setValues((values) => ({
                    ...values,
                    customFields: {
                      ...values.customFields,
                      [userField.name]: value,
                    },
                  }));
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.agreeToMarketingNewsletters}
                  onChange={() =>
                    setValues({
                      ...values,
                      agreeToMarketingNewsletters:
                        !values.agreeToMarketingNewsletters,
                    })
                  }
                />
              }
              label={t("settingsProfile.agreeToMarketingNewsletters")}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Root>
  );
}

SettingsProfile.propTypes = {
  onClose: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  arena: PropTypes.shape({
    multilingual: PropTypes.bool,
    userFields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
  }),
};

export default withSnackbar(SettingsProfile);
