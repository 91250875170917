import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { invoice } from "@telegram-apps/sdk";
import { LinearProgress } from "@mui/material";
import ErrorScreen from "core/components/ErrorScreen";

export default function TelegramPaymentForm({
  // descriptionComponent,
  paymentData,
  returnUrl,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (!invoice.open.isAvailable()) {
      setLoading(false);

      return setError(
        new Error("Telegram invoice is not supported outside of mini app")
      );
    }

    invoice
      .open(paymentData.invoiceLink, "url")
      .then((result) => {
        console.info("invoice result", result);

        if (result === "cancelled") {
          return setLoading(false);
        }

        window.location = returnUrl;

        return;
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (error)
    return (
      <Box sx={{ p: 4 }}>
        <ErrorScreen text={error.message} />
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {loading && <LinearProgress />}

        {/* {descriptionComponent && (
          <Grid item xs={12}>
            {descriptionComponent}
          </Grid>
        )} */}
      </Grid>
    </Box>
  );
}

TelegramPaymentForm.propTypes = {
  descriptionComponent: PropTypes.object,
  paymentData: PropTypes.object,
  returnUrl: PropTypes.string,
  amount: PropTypes.object.isRequired,
};
