import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import contentUrl from "core/utils/contentUrl";
import Thumbnail from "core/components/Thumbnail";
import ThumbnailProgress from "core/components/ThumbnailProgress";
import { truncate } from "core/utils/string";

const PREFIX = "ContentGridItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  mediaLink: `${PREFIX}-mediaLink`,
  body: `${PREFIX}-body`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderContent: `${PREFIX}-cardHeaderContent`,
  link: `${PREFIX}-link`,
  linkSmall: `${PREFIX}-linkSmall`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    backgroundColor: "#151515",
  },

  [`& .${classes.mediaLink}`]: {
    position: "relative",
    display: "block",
    color: "inherit",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4, // desktop
    },
  },

  [`& .${classes.body}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",

    padding: theme.spacing(1, 0),

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.5, 0),
    },
  },

  [`& .${classes.cardHeaderContent}`]: {
    width: "100%",
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "-webkit-line-clamp": 2,

    fontSize: "0.9rem", // xs

    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
  },

  // Если в группе показывается больше 3 элементов, то надо делать текст еще меньше
  [`& .${classes.linkSmall}`]: {
    fontSize: "0.9rem", // xs

    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem",
    },
  },
}));

export default function ContentGridItem({
  start,
  finish,
  content,
  play,
  className,
  productId,
  collectionId,
  smallTitle, // если в группе больше 3 элементов, то надо делать текст еще меньше
}) {
  const to = contentUrl(content.siteUrl, {
    start,
    finish,
    productId,
    collectionId,
  });

  return (
    <Root className={className}>
      <CardActionArea
        component={Link}
        to={to}
        className={classNames(classes.mediaLink)}
      >
        <Thumbnail
          content={content}
          play={play}
          start={start}
          finish={finish}
        />

        <ThumbnailProgress content={content} />
      </CardActionArea>

      <CardHeader
        className={classes.cardHeader}
        title={truncate(content.title, 50)}
        classes={{
          content: classes.cardHeaderContent,
        }}
        titleTypographyProps={{
          color: "textPrimary",
          className: classNames({
            [classes.link]: true,
            [classes.linkSmall]: smallTitle,
          }),
          component: Link,
          to,
        }}
      />
    </Root>
  );
}

ContentGridItem.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }),
  smallTitle: PropTypes.bool,
  play: PropTypes.bool,
  className: PropTypes.string,
  productId: PropTypes.string,
  collectionId: PropTypes.string,
  start: PropTypes.number,
  finish: PropTypes.number,
};
