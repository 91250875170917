import React, { useEffect } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { useMutation } from "@apollo/client";
import { SignInByTelegramMutation } from "arena/mutations";
import ErrorScreen from "core/components/ErrorScreen";
import AuthenticatingScreen from "arena/components/AuthenticatingScreen";

export default function AuthByTelegram() {
  const [signInByTelegram, { error, called }] = useMutation(
    SignInByTelegramMutation,
    {
      refetchQueries: ["Viewer"],
      onCompleted: () => {
        console.log("signInByTelegram onCompleted");
      },
      onError: (error) => {
        console.log("signInByTelegram onError", error);
      },
    }
  );

  useEffect(() => {
    if (called) return;

    const { initDataRaw } = retrieveLaunchParams();

    signInByTelegram({
      variables: {
        initData: initDataRaw,
      },
    });
  }, [called, signInByTelegram]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return <AuthenticatingScreen />;
}
