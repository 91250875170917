import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import OnboardingDialog from "arena/components/OnboardingDialog";
import EmailErrorDialog from "arena/components/EmailErrorDialog";
import { useViewer } from "core/components/ViewerProvider";
import AppDrawer from "arena/components/AppDrawer";
import Header from "arena/components/Header";
import Footer from "arena/components/Footer";
import SettingsDialog from "arena/components/SettingsDialog";
import MenuItemDialog from "arena/components/MenuItemDialog";
import SignInDialog from "core/components/SignInDialog";
import { useApolloClient, useMutation } from "@apollo/client";
import { SignOutMutation } from "arena/mutations";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import LocalStorage from "core/utils/localstorage";
import SettingsIcon from "@mui/icons-material/Settings";
import ShopIcon from "@mui/icons-material/Shop";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTranslation } from "react-i18next";
import { isTelegramMiniApp } from "arena/utils/telegram";

const Root = styled("div")({
  minHeight: "100vh",
});

const Content = styled("main")(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  paddingTop: 54,
  [theme.breakpoints.up("sm")]: {
    paddingTop: 64,
  },
}));

const ls = new LocalStorage(`apollo`);

export default function AppShell({ arena, children }) {
  const { t } = useTranslation("arena");
  const viewer = useViewer();
  const client = useApolloClient();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const hasSignInParam = searchParams.has("signin");
  const oauthConnect = searchParams.get("oauthConnect");
  const addDevice = searchParams.get("addDevice");
  const hasAddDevice = searchParams.has("addDevice");

  const [mobileOpen, setMobileOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(hasAddDevice || hasSignInParam);
  const [settingsOpen, setSettingsOpen] = useState(
    oauthConnect || hasAddDevice ? true : false
  );

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!hasSignInParam) return;

    if (!signInOpen) {
      if (hasSignInParam) {
        setSignInOpen(true);
      }
    }

    // Очистим siginin в адресной строке
    navigate(location.pathname, { replace: true });
  }, [hasSignInParam]);

  const [menuItemDialog, setMenuItemDialog] = useState();

  const [signOut] = useMutation(SignOutMutation, {
    onCompleted() {
      ls.removeItem("token");
      client.resetStore();
    },
  });

  const onCloseMobileDrawer = () => {
    setMobileOpen(false);
    ReactGA.event({
      category: "Header",
      action: "Close mobile menu",
    });
  };

  const onClickMenuButton = () => {
    setMobileOpen((v) => !v);
    ReactGA.event({
      category: "Header",
      action: "Click to menu button",
    });
  };

  const onCloseSignIn = () => {
    setSignInOpen(false);
    ReactGA.event({
      category: "SignInDialog",
      action: "Close dialog",
    });
  };

  const onCloseSettingsDialog = () => {
    setSettingsOpen(false);
    ReactGA.event({
      category: "SettingsDialog",
      action: "Close dialog",
    });
  };

  const onClickSignIn = () => {
    setSignInOpen(true);
    ReactGA.event({
      category: "Header",
      action: "Click to sign in button",
    });
  };

  const onClickSearch = () => {
    ReactGA.event({
      category: "Header",
      action: "Click to search button",
    });
  };

  const onClickMenuItem = (event, menuItem) => {
    if (menuItem.type === "DIALOG") {
      setMenuItemDialog(menuItem);
      ReactGA.event({
        category: "Header",
        action: "Click to menu item (dialog)",
        label: menuItem.label,
      });
    }

    if (menuItem.type === "LINK") {
      ReactGA.event({
        category: "Header",
        action: "Click to menu item (link)",
        label: menuItem.label,
      });
    }
  };

  const onClickMobileMenuItem = (event, menuItem) => {
    setMobileOpen(false);
    if (menuItem.type === "DIALOG") {
      setMenuItemDialog(menuItem);
      ReactGA.event({
        category: "MobileMenu",
        action: "Click to menu item (dialog)",
        label: menuItem.label,
      });
    }

    if (menuItem.type === "LINK") {
      ReactGA.event({
        category: "MobileMenu",
        action: "Click to menu item (link)",
        label: menuItem.label,
      });
    }
  };

  const onCloseMenuItemDialog = () => {
    ReactGA.event({
      category: "MobileMenuItemDialog",
      action: "Close",
      label: menuItemDialog.label,
    });
    setMenuItemDialog();
  };

  const menuItems = [
    {
      icon: SettingsIcon,
      onClick() {
        setSettingsOpen(true);
        ReactGA.event({
          category: "Header",
          action: "Click to settings in user menu",
        });
      },
      primary: t("userMenu.settings"),
      access: true,
    },
    {
      icon: ShopIcon,
      to: "/purchases",
      onClick() {
        ReactGA.event({
          category: "Header",
          action: "Click to purchases in user menu",
        });
      },
      primary: t("userMenu.purchases"),
      access: true,
    },
    {
      icon: FavoriteIcon,
      to: "/favorites",
      onClick() {
        ReactGA.event({
          category: "Header",
          action: "Click to favorites in user menu",
        });
      },
      primary: t("userMenu.favorites"),
      access: arena.isFavoritesEnabled,
    },
    {
      icon: PowerSettingsNewIcon,
      onClick() {
        signOut();
        setSettingsOpen(false);
        ReactGA.event({
          category: "Header",
          action: "Click to logout",
        });
      },
      primary: t("userMenu.logout"),
      access: !isTelegramMiniApp,
    },
  ].filter((item) => Boolean(item.access));

  return (
    <Root>
      <Content>
        {children}

        <Footer arena={arena} />
      </Content>

      <Header
        arena={arena}
        menuItems={menuItems}
        onClickMenuMobile={onClickMenuButton}
        onClickSignIn={onClickSignIn}
        onClickSearch={onClickSearch}
        onClickMenuItem={onClickMenuItem}
      />

      <AppDrawer
        open={mobileOpen}
        onClose={onCloseMobileDrawer}
        onClickMenuItem={onClickMobileMenuItem}
        viewer={viewer}
        arena={arena}
      />

      {viewer && <OnboardingDialog arena={arena} viewer={viewer} />}
      {viewer?.emailError && <EmailErrorDialog viewer={viewer} />}
      {viewer ? (
        <SettingsDialog
          oauthConnect={oauthConnect}
          hasAddDevice={hasAddDevice}
          addDevice={addDevice}
          open={settingsOpen}
          onClose={onCloseSettingsDialog}
        />
      ) : (
        <SignInDialog
          arena={arena}
          open={signInOpen}
          onClose={onCloseSignIn}
          location={location}
        />
      )}

      <MenuItemDialog
        open={!!menuItemDialog}
        onClose={onCloseMenuItemDialog}
        menuItem={menuItemDialog}
      />
    </Root>
  );
}

AppShell.propTypes = {
  children: PropTypes.node,
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isFavoritesEnabled: PropTypes.bool,
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          url: PropTypes.string,
        }),
      })
    ),
  }),
};
