import React from "react";
import { styled } from "@mui/material/styles";
import usePageview from "core/hooks/usePageview";
import useScrollToTop from "core/hooks/useScrollToTop";
import ArenaContents from "arena/components/ArenaContents";
import { useTranslation } from "react-i18next";
import { useArena } from "core/components/ArenaProvider";
import { TagQuery } from "arena/queries";
import { useQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import { useLocation, useSearchParams } from "react-router-dom";
import ArenaHelmet from "arena/components/ArenaHelmet";

const PREFIX = "Search";

const classes = {
  form: `${PREFIX}-form`,
};

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(2, 2, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 0, 0),
    },
  },
}));

export default function Search() {
  const arena = useArena();
  const location = useLocation();

  const { t } = useTranslation("arena");

  usePageview(location.pathname, "Search");
  useScrollToTop();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const tagId = searchParams.get("tagId");
  const tagIds = searchParams.getAll("tagId");

  const { data } = useQuery(TagQuery, {
    skip: !tagId,
    variables: {
      id: tagId,
    },
  });

  const tag = data?.tag?.title;

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const query = formData.get("query");

    searchParams.set("query", query);

    return setSearchParams(searchParams);
  };

  return (
    <Root>
      <ArenaHelmet title={tag || t("search.result")} />
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          name="query"
          autoFocus
          fullWidth
          variant="outlined"
          defaultValue={query}
          placeholder={t("search.placeholder")}
        />
      </form>

      <ArenaContents
        search={query}
        arena={arena}
        view="GRID"
        sort="LATEST"
        limit={100}
        tagIds={tagIds}
        title={tag || t("search.result")}
        loadMore
        noMore
        inView
      />
    </Root>
  );
}
