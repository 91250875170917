import React from "react";
import { SvgIcon } from "@mui/material";

export default function StarsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 158 158" fill="none" {...props}>
      <path
        d="M87.0544 5.37346L103.777 43.0171C105.071 45.9299 107.713 47.9367 110.763 48.3232L150.177 53.3169C157.679 54.2674 160.69 63.9545 155.155 69.3329L126.076 97.5918C123.826 99.7784 122.817 103.025 123.408 106.177L131.044 146.907C132.498 154.659 124.615 160.646 118.012 156.806L83.3176 136.627C80.633 135.065 77.3671 135.065 74.6825 136.627L39.988 156.806C33.3847 160.646 25.5022 154.659 26.9557 146.907L30.0182 128.449C31.3294 120.546 36.1834 113.784 43.0669 110.27L83.2471 89.7595C84.1413 89.303 83.7627 87.888 82.7723 87.9847L40.1326 92.1483C34.8584 92.6633 29.5946 91.0582 25.4085 87.6584L2.84491 69.3329C-2.68959 63.9545 0.321257 54.2674 7.82283 53.3169L47.2369 48.3232C50.2867 47.9367 52.9289 45.9299 54.2228 43.0171L70.9456 5.37346C74.1284 -1.79115 83.8717 -1.79115 87.0544 5.37346Z"
        fill="white"
      />
    </SvgIcon>
  );
}
